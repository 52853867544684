import GATSBY_COMPILED_MDX from "/builds/prismatic-io/website/content/customers/sisu-software.mdx";
import React from "react";
import {styled} from "@mui/material";
import Layout from "@/layout";
import {Container} from "@/components/Container";
import {MarkdownStyled} from "@/components/markdown";
import {SEO} from "@/layout/SEO";
export const DefaultStyled = styled(Container)(_ref => {
  let {theme} = _ref;
  return {
    margin: theme.spacing(13.5, "auto", 10),
    "& > div > .hero:first-child": {
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(-7.5)
    }
  };
});
const Default = _ref2 => {
  let {data, location, children} = _ref2;
  const {customFooterCta, footerCta, minimalNavigation} = data.page.frontmatter;
  return React.createElement(Layout, {
    customFooterCta: customFooterCta,
    footerCta: footerCta,
    minimalNavigation: minimalNavigation
  }, React.createElement(DefaultStyled, {
    maxWidth: 1068
  }, React.createElement(MarkdownStyled, null, children)));
};
const query = "346984969";
export const Head = _ref3 => {
  let {location, data, params, pageContext} = _ref3;
  const {author, description, featureImage, metaDescription, robots, schemaOrg, seoImage, title, titleTag} = data.page.frontmatter;
  return React.createElement(SEO, {
    author: author,
    description: description,
    featureImage: featureImage,
    metaDescription: metaDescription,
    pathname: location.pathname,
    robots: robots,
    schemaOrg: schemaOrg,
    seoImage: seoImage,
    title: title,
    titleTag: titleTag
  });
};
Default
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Default, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
