/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    h4: "h4",
    p: "p",
    strong: "strong",
    h2: "h2"
  }, _provideComponents(), props.components), {Hero, Columns, Column, Background, List, Video, Testimonial, Cta} = _components;
  if (!Background) _missingMdxReference("Background", true);
  if (!Column) _missingMdxReference("Column", true);
  if (!Columns) _missingMdxReference("Columns", true);
  if (!Cta) _missingMdxReference("Cta", true);
  if (!Hero) _missingMdxReference("Hero", true);
  if (!List) _missingMdxReference("List", true);
  if (!Testimonial) _missingMdxReference("Testimonial", true);
  if (!Video) _missingMdxReference("Video", true);
  return React.createElement(React.Fragment, null, React.createElement(Hero, {
    headline: "Customer Story: Sisu Software",
    text: "How Sisu used Prismatic to accelerate integrations, resulting in 5x revenue per subscription and 3% less churn.",
    backgroundImage: "primary",
    background: "deepPurple",
    color: "white01",
    size: "xsmall"
  }), "\n", React.createElement(Columns, null, React.createElement(Column, {
    md: 4
  }, React.createElement(Background, {
    background: "gray03",
    color: "deepPurple",
    fullWidth: false
  }, React.createElement(_components.h3, {
    id: "key-results",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#key-results",
    "aria-label": "key results permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Key Results"), React.createElement(_components.h4, {
    id: "6x-faster-integration-delivery",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#6x-faster-integration-delivery",
    "aria-label": "6x faster integration delivery permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "6x faster integration delivery"), React.createElement(_components.h4, {
    id: "5x-revenue-per-subscription",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#5x-revenue-per-subscription",
    "aria-label": "5x revenue per subscription permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "5x revenue per subscription"), React.createElement(_components.h4, {
    id: "3-reduction-in-monthly-churn",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-reduction-in-monthly-churn",
    "aria-label": "3 reduction in monthly churn permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3% reduction in monthly churn")), React.createElement(Background, {
    background: "gray03",
    color: "deepPurple",
    fullWidth: false
  }, React.createElement(_components.h3, {
    id: "about-sisu",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#about-sisu",
    "aria-label": "about sisu permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "About Sisu"), React.createElement(_components.p, null, "Sisu is a real estate workflow automation solution that helps real estate teams be more productive through dashboards and leaderboards, sales contest automation, and performance analytics."), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Industry:"), " Real estate"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Size:"), " 40 employees"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Website:"), " ", React.createElement(_components.a, {
    href: "https://sisu.co/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "sisu.co"))), React.createElement(Background, {
    background: "gray03",
    color: "deepPurple",
    fullWidth: false
  }, React.createElement(_components.h3, {
    id: "why-prismatic",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-prismatic",
    "aria-label": "why prismatic permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why Prismatic"), React.createElement(List, {
    variant: "check"
  }, React.createElement("li", null, "Offloads hassle of scaling infrastructure"), React.createElement("li", null, "Supports the complexity typical of Sisu's integrations"), React.createElement("li", null, "Enables success team to deploy & support integrations")))), React.createElement(Column, {
    md: 8
  }, React.createElement(Video, {
    image: "/images/customers/frank-felice-thumbnail.png",
    videoId: "821739458"
  }), React.createElement(_components.h2, {
    id: "challenge",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#challenge",
    "aria-label": "challenge permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Challenge"), React.createElement(_components.p, null, "Frank Felice, Chief Revenue Officer at Sisu Software, knew integrations were critical to Sisu's SaaS offering. They add huge customer value and create stickiness. \"The more integrations that we have customers using, the likelihood for them to churn or move off our platform decreases,\" he said."), React.createElement(_components.p, null, "As a real estate team operating system, Sisu sits in the middle of its customers' tech stacks. Each customer needs an integration to their real estate CRM – there are around 10 popular ones – plus around 5 more real estate specific solutions."), React.createElement(_components.p, null, "Sisu had built their first couple integrations internally. It took up to 8 months to get an integration from idea to go-live. Most of the integration know-how was siloed with a single Sisu engineer, who spent around a third of his time on integration support alone. The integrations were slow and not scaling well."), React.createElement(_components.p, null, "The final straw was when Sisu launched one of their integrations and deployed it to 200 customers."), React.createElement(Testimonial, {
    id: "sisu-1",
    color: "deepPurple"
  }), React.createElement(_components.h2, {
    id: "solution",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#solution",
    "aria-label": "solution permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Solution"), React.createElement(_components.p, null, "Frank knew that Sisu needed a more scalable approach and that embedded iPaaS was the key. His first step was getting everyone comfortable with exploring commercial integration platforms rather than building one in house. He pointed out a similar decision Sisu had faced before. They could have built a billing and subscription system, but had used an embeddable product instead."), React.createElement(Testimonial, {
    id: "sisu-2",
    color: "deepPurple",
    padding: "none"
  }), React.createElement(_components.p, null, "Before long, the team was on board. They pulled together requirements from an integration they'd built previously and asked several vendors to demonstrate how their embedded iPaaS would support it."), React.createElement(_components.p, null, "Of the vendors, Prismatic was the only one who successfully built the integration. This gave the team confidence that Prismatic could handle the type and complexity of integrations Sisu needed to build."), React.createElement(_components.p, null, "\"From day one, Prismatic was amazing. They stepped up, they wrote the integration, they understood the requirements, they were able to turn around and quickly cycle through and do the demonstration,\" said Frank."), React.createElement(_components.h2, {
    id: "results",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#results",
    "aria-label": "results permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Results"), React.createElement(_components.p, null, "Sisu's integration velocity has massively increased. Integrations that would previously take 6 to 8 months to build can be completed in 2 weeks, and the engineering team can now give accurate timeline estimates."), React.createElement(_components.p, null, "Their integrations, now running on Prismatic's purpose-built infrastructure, have scaled easily across hundreds of customers."), React.createElement(_components.p, null, "Today, integration knowledge is shared across a team of 7 developers. They build integrations in Prismatic and then turn them over to Sisu's success team for deployment and support."), React.createElement(_components.p, null, "\"It allows our developers to focusing on developing,\" said Frank. \"It's been a game changer to give our customer success team and our technical support team access into Prismatic so they can look at logs and provide better customer support.\""), React.createElement(_components.p, null, "Sisu has also seen big wins in customer retention and revenue."), React.createElement(Testimonial, {
    id: "sisu-3",
    color: "deepPurple"
  }), React.createElement(_components.p, null, "\"Would I recommend Prismatic? Absolutely, 100%. The Prismatic solution gives us a strong competitive edge in our space,\" Frank concluded."), React.createElement(Cta, {
    title: "Inspired by what Sisu achieved?",
    copy: "Get a tailored demo to see how Prismatic can help your team deliver powerful native integrations, fast.",
    label: "Book a Demo",
    to: "https://prismatic.io/request-a-demo/"
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
